import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import RegistrationPage from "./Registration_Page";
//import PrivateRoute from "./PrivateRoute";
import AudioWorkFlow from "./Survey/index";
import Dashboard from "./Survey/Dashboard";
import TaskerLogin from "./Survey/TaskerLogin";
import RatingsDashboard from "./components/UserDashboard/UserRatingsDashboard";
import TaskerEarningsDashboard from "./components/UserDashboard/TaskerEarningsPage";
import TransactionHistory from "./components/UserDashboard/TransactionHistory";
import PaymentForm from "./components/UserDashboard/TakserPaymentForm";
import ReferralShare from "./components/TaskDashboardComponents/ReferralShareComponent";
import ReferralLanding from "./components/TaskDashboardComponents/ReferralLanding";
import UpdateLanguageUI from "./Survey/UpdateLanguageUI";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/registration" element={<RegistrationPage />} />
        <Route path="/" element={<TaskerLogin />} />
        <Route path="/tasker-dashboard" element={<Dashboard />} />
        <Route path="/audio-task" element={<AudioWorkFlow />} />
        <Route path="*" element={<Navigate to="/" />} />
        <Route path="/tasker-rating-dashboard" element={<RatingsDashboard />} />
        <Route path="/tasker-earning-dashboard" element={<TaskerEarningsDashboard />} />
        <Route path="/transaction-history" element={<TransactionHistory />} />
        <Route path="/tasker-payment-form" element={<PaymentForm />} />
        <Route path="/tasker-referral-page" element={<ReferralShare />} />
        <Route path="/details" element={<ReferralLanding />} />
        <Route path="/update-language-preference" element={<UpdateLanguageUI />} />
      </Routes>
    </Router>
  );
}
export default App;