import styled from "styled-components";
export const LogoName = styled.div`
  height: 120px;
  width: 280px;
  img {
    height: 120px;
    width: 280px;
    object-fit: cover;
  }
  @media (max-width: 576px) {
    height: 120px;
    width: 220px;
    img {
      height: 120px;
      width: 220px;
      object-fit: cover;
    }
  }
`;

export const LogedInLogoName = styled.div`
  height: 120px;
  width: 280px;
  position: absolute;
  top: 50%; /* Center vertically */
  left: 50%; /* Center horizontally */
  transform: translate(-50%, -50%); /* Adjust the element to be perfectly centered */

  img {
    height: 120px;
    width: 280px;
    object-fit: cover;
    opacity: 0; /* Start as invisible */
    transform: scale(0); /* Start at 0 scale (small) */
    animation: grow 1s ease-out forwards; /* Apply the animation */
  }

  @media (max-width: 576px) {
    height: 150px;
    width: 280px;
    position: absolute;
    top: 50%; /* Center vertically */
    left: 50%; /* Center horizontally */
    transform: translate(-50%, -50%); /* Adjust the element to be perfectly centered */
    img {
      height: 150px;
      width: 280px;
      object-fit: cover;
      opacity: 0; /* Start as invisible */
      transform: scale(0); /* Start at 0 scale (small) */
      animation: grow 1s ease-out forwards; /* Apply the animation */
    }
  }

  /* Keyframes for the growth animation */
  @keyframes grow {
    0% {
      opacity: 0; /* Start as invisible */
      transform: scale(0); /* Start small */
    }
    100% {
      opacity: 1; /* Fade in */
      transform: scale(1); /* Scale to original size */
    }
  }
`;
export const InfoHeading = styled.p`
  text-align: center;
  color: #131cd9;
  font-weight: 600;
  padding: 2px 4px;
  @media (max-width: 576px) {
    font-size: 14px;
    margin: 4px 0 14px 0;
  }
`;
export const StepCount = styled.p`
  text-align: right;
  color: #131cd9;
  font-weight: 600;
  padding: 2px 4px;
`;
export const LocationModal = styled.div`
  text-align: center;
  p {
    text-align: center;
  }
  button {
    padding: 6px 18px;
    font-size: 16px;
    background-color: #0a275e;
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 16px;
    &:hover {
      border: 1px solid #0b2658;
      background-color: #d7e2f7;
      color: #0b2658;
    }
  }
  @media (max-width: 576px) {
    button {
      padding: 4px 16px;
      font-size: 14px;
    }
  }
`;
export const InputContainer = styled.div`
  min-width: 450px;
  fieldset {
    margin-bottom: 20px;
    border: 1px solid #5e96f7;
    background-color: rgb(194 215 251);
    padding: 10px 16px;
    box-shadow: 0 0px 2px rgb(107 107 107);
    border-radius: 4px;
    overflow: visible;
  }

  legend {
    border: 1px solid #5d96ff;
    padding: 6px 12px;
    border-radius: 4px;
    font-size: 16px;
    font-weight: bold;
    background-color: #c2d7fe;
    width: auto;
    margin: 0px;
    display: inline-block;
    text-align: center;
    color: #0b2658;
  }

  input {
    margin-top: 4px;
    width: 100%;
    padding: 9px 12px;
    border: 1px solid #e1e2eb;
    color: #102a43;
    box-sizing: border-box;
    font-size: 16px;
    line-height: 1.3rem;
    box-shadow: inset 0 2px 4px 0 rgba(206, 209, 224, 0.2);
    border-radius: 3px;
  }
  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
    background-repeat: no-repeat;
    background-position-x: 95%;
    background-position-y: 6px;
    margin-top: 4px;
    width: 100%;
    padding: 9px 12px;
    border: 1px solid #e1e2eb;
    color: #102a43;
    box-sizing: border-box;
    font-size: 16px;
    line-height: 1.3rem;
    box-shadow: inset 0 2px 4px 0 rgba(206, 209, 224, 0.2);
    border-radius: 3px;
  }
  @media (max-width: 576px) {
    min-width: 350px;
    fieldset {
      padding: 14px 12px;
    }
    legend {
      padding: 6px 8px;
      font-size: 14px;
    }
    label {
      font-size: 14px;
    }
    input {
      padding: 10px 10px;
      font-size: 16px;
    }
  }
`;
export const NextBtn = styled.button`
  padding: 6px 18px;
  font-size: 16px;
  background-color: #0a275e;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 16px;
  transition: background-color 0.3s, color 0.3s; // Add a transition for smoothness

  &:active {
    background-color: #d7e2f7;
    color: #0b2658;
    border: 1px solid #0b2658;
    transform: scale(0.98); // Slightly shrink for a pressed effect
  }

  &:focus {
    outline: none; // Remove default focus outline
  }

  @media (max-width: 576px) {
    padding: 6px 16px;
    font-size: 14px;
  }
`;
export const PreviousBtn = styled.button`
  padding: 6px 18px;
  font-size: 16px;
  background-color: #0a275e;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 16px;
  &:hover {
    border: 1px solid #0b2658;
    background-color: #d7e2f7;
    color: #0b2658;
  }
  @media (max-width: 576px) {
    padding: 6px 16px;
    font-size: 14px;
  }
`;

export const AddLangBtn = styled.button`
  padding: 4px 12px;
  font-size: 14px;
  background-color: #1046b1;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 16px;
  &:hover {
    border: 1px solid #0b2658;
    background-color: #d7e2f7;
    color: #0b2658;
  }
  @media (max-width: 576px) {
    padding: 6px 16px;
    font-size: 14px;
  }
`;
export const SkillContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 8px;
  button {
    padding: 4px 12px;
    font-size: 14px;
    background-color: #9c1630;
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 16px;
    &:hover {
      border: 1px solid #971313;
      background-color: #fcdbdb;
      color: #951414;
    }
  }
  label {
    display: flex;
    align-items: flex-end;
    font-size: 16px;
    gap: 2px;
  }
`;
export const LanguageCheckbox = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 24px;
  label {
    font-size: 16px;
    display: flex;
    align-items: center;
    gap: 8px;
    input {
      width: 16px;
      height: 16px;
      margin: 0;
    }
  }

  @media (max-width: 576px) {
    gap: 14px;
    label {
      font-size: 14px;
      display: flex;
      align-items: center;
      gap: 6px;

      input {
        width: 14px;
        height: 14px;
        margin: 0;
      }
    }
  }
`;

export const LoginInputContainer = styled.div`
  min-width: 450px;
  fieldset {
    margin-bottom: 20px;
    border: 1px solid #5e96f7;
    background-color: rgb(194 215 251);
    padding: 10px 16px;
    box-shadow: 0 0px 2px rgb(107 107 107);
    border-radius: 4px;
    overflow: visible;
  }

  legend {
    border: 1px solid #5d96ff;
    padding: 6px 12px;
    border-radius: 4px;
    font-size: 16px;
    font-weight: bold;
    background-color: #c2d7fe;
    width: auto;
    margin: 0px;
    display: inline-block;
    text-align: center;
    color: #0b2658;
  }

  input {
    margin-top: 4px;
    width: 100%;
    padding: 9px 12px;
    border: 1px solid #e1e2eb;
    color: #102a43;
    box-sizing: border-box;
    font-size: 16px;
    line-height: 1.3rem;
    box-shadow: inset 0 2px 4px 0 rgba(206, 209, 224, 0.2);
    border-radius: 3px;
  }
  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
    background-repeat: no-repeat;
    background-position-x: 95%;
    background-position-y: 6px;
    margin-top: 4px;
    width: 100%;
    padding: 9px 12px;
    border: 1px solid #e1e2eb;
    color: #102a43;
    box-sizing: border-box;
    font-size: 16px;
    line-height: 1.3rem;
    box-shadow: inset 0 2px 4px 0 rgba(206, 209, 224, 0.2);
    border-radius: 3px;
  }
  @media (max-width: 576px) {
    min-width: 350px;
    fieldset {
      padding: 14px 12px;
    }
    legend {
      padding: 6px 8px;
      font-size: 14px;
    }
    label {
      font-size: 14px;
    }
    input {
      padding: 10px 10px;
      font-size: 16px;
    }
  }
`;

export const LoginNextBtn = styled.button`
  padding: 6px 18px;
  font-size: 16px;
  background-color: #4a1e6b;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 16px;
  transition: background-color 0.3s, color 0.3s; // Add a transition for smoothness

  &:active {
    background-color: #e4bcfa;
    color: #0b2658;
    border: 1px solid #0b2658;
    transform: scale(0.98); // Slightly shrink for a pressed effect
  }

  &:focus {
    outline: none; // Remove default focus outline
  }

  @media (max-width: 576px) {
    padding: 6px 16px;
    font-size: 14px;
  }
`;

export const ResendOtpBtn = styled.div`
  margin-top: 5px;
  p {
    margin: 0;
    padding: 5px 0;
    font-size: 14px;
  }
  button {
    background: transparent;
    color: #0a0a72;
    font-weight: 600;
    text-decoration: underline;
    padding: 4px;
    border: none;

    &:active {
      background-color: #e4bcfa;
      color: #0b2658;
      border: 1px solid #0b2658;
      transform: scale(0.98); // Slightly shrink for a pressed effect
    }

    &:focus {
      outline: none; // Remove default focus outline
    }

    @media (max-width: 576px) {
      padding: 4px;
      font-size: 14px;
    }
  }
`;

export const RegisterBtn = styled.button`
  background: transparent;
  border: none;
  color: #061531;
  font-weight: 600;
  text-decoration: underline;
  padding: 0;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s; // Add a transition for smoothness

  &:active {
    background-color: #d7e2f7;
    color: #0b2658;
    border: 1px solid #0b2658;
    transform: scale(0.98); // Slightly shrink for a pressed effect
  }

  &:focus {
    outline: none; // Remove default focus outline
  }

  @media (max-width: 576px) {
    padding: 6px 16px;
    font-size: 14px;
  }
`;