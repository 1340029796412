import React, { useEffect, useState } from "react";
import "./TaskerEarningsStyles.css";
import logo from "../../Image/logo3.png";
import SiccaConversionCard from "./SiccaConversionCard";
import { useNavigate } from "react-router-dom";
import { showNotification } from "../../actions/index.actions";
import api from "../../api";

const TaskerEarningsDashboard = () => {
  // const userNumber = localStorage.getItem("userNumber");
  const sessionKey = localStorage.getItem("sessionKey");
  const [earningsData, setEarningsData] = useState({
    yesterday: "0",
    monthToDate: "0",
    yearToDate: "0",
    lifetime: "0",
    currentBalance: "0",
  });

  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    if (!sessionKey) {
      showNotification(
        "Authentication Required",
        "Please log in to view your earnings",
        "warning"
      );
      window.open("/", "_self");
      return;
    }

    const fetchEarnings = async () => {
      try {
        setIsLoading(true);
        setError("");

        const response = await api.get(`/tasker-dashboard/tasker-earnings`, {
          params: { sessionKey: sessionKey },
          headers: { "Cache-Control": "no-cache" },
        });

        const data = await response.data;
        setEarningsData(data);
      } catch (err) {
        const errorMessage =
          err.response?.data?.message ||
          "Failed to load earnings data. Please try again later.";
        setError(errorMessage);
        showNotification("Error", errorMessage, "danger");
      } finally {
        setIsLoading(false);
      }
    };

    fetchEarnings();
  }, [sessionKey]);

  const handleNavigate = (path) => {
    try {
      navigate(path);
    } catch (error) {
      showNotification(
        "Navigation Error",
        "Failed to navigate to the requested page",
        "danger"
      );
    }
  };

  if (isLoading) {
    return (
      <div className="container">
        <div className="loading">Loading earnings data...</div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="container">
        <div className="error">{error}</div>
      </div>
    );
  }

  return (
    <div className="container">
      <div className="header">
        <img src={logo} alt="Logo" className="header-logo" />
        <h1 className="header-title">Earnings</h1>
      </div>

      <div className="card">
        <div className="card-content">
          <div className="left-section">
            <h2 className="section-title">Tasker Sicca</h2>
            <img src={logo} alt="Logo" className="logo" />
          </div>

          <div className="right-section">
            <h2 className="section-title">Earnings</h2>
            <div className="earnings-list">
              <div className="earnings-item">
                <span className="earnings-label">Yesterday</span>
                <span className="earnings-value">{earningsData.yesterday}</span>
              </div>
              <div className="earnings-item">
                <span className="earnings-label">Month to Date</span>
                <span className="earnings-value">
                  {earningsData.monthToDate}
                </span>
              </div>
              <div className="earnings-item">
                <span className="earnings-label">Year to Date</span>
                <span className="earnings-value">
                  {earningsData.yearToDate}
                </span>
              </div>
              <div className="earnings-item">
                <span className="earnings-label">Lifetime</span>
                <span className="earnings-value">{earningsData.lifetime}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <SiccaConversionCard earningsData={earningsData} />
      <div className="more-options">
        <h2 className="section-title">More Options</h2>
        <div className="options-buttons">
          <button
            className="transaction-button"
            onClick={() => handleNavigate("/transaction-history")}
          >
            Transactions
          </button>
          <button
            className="transaction-button"
            onClick={() => handleNavigate("/tasker-payment-form")}
          >
            Payment Details
          </button>
        </div>
      </div>
    </div>
  );
};

export default TaskerEarningsDashboard;
