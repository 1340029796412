import React, { useState, useEffect } from "react";
import api from "../api";
import FullpageLoader from "../elements/FullpageLoader";
import { showNotification } from "../actions/index.actions";
import ErrorUI from "../elements/ErrorUI";
import UpdateLanguageUiCss from "./UpdateLanguageUi.css";
import { NextBtn } from "../Style";
const UpdateLanguageUI = () => {
    const [isLoading, setLoading] = useState(true);
    const [isUpdating, setUpdating] = useState(false);
    const [isError, setError] = useState(false);
    const [modifiedLanguages, setModifiedLanguages] = useState([]);
    const sessionKey = localStorage.getItem("sessionKey");
    useEffect(() => {
        const sessionKey = localStorage.getItem("sessionKey");
        if (!sessionKey) {
            window.open("/", "_self");
        } else {
            fetchLanguages(sessionKey);
        }
    }, []);

    // Fetch languages from the API
    const fetchLanguages = async (sessionKey) => {
        try {
            setLoading(true);
            setError(false);
            const response = await api.get("/api/fetch-user-language", {
                params: { sessionKey },
                headers: { "Cache-Control": "no-cache" },
            });
            if (response.status === 200) {
                setModifiedLanguages(response.data.list);
                setLoading(false);
            }
        } catch (err) {
            setError(true);
            setLoading(false);
        }
    };

    // Handle checkbox changes for each language
    const handleCheckboxChange = (id, field, value) => {
        const updatedLanguages = modifiedLanguages.map((lang) => {
            if (lang.ID === id) {
                return { ...lang, [field]: value };
            }
            return lang;
        });
        setModifiedLanguages(updatedLanguages);
    };

    // Validate that each language has at least one of isSpeak, isRead, or isWrite set to 1
    const validateLanguages = () => {
        for (const lang of modifiedLanguages) {
            if (![lang.isSpeak, lang.isRead, lang.isWrite].includes(1)) {
                return false; // If none of them are 1, return false
            }
        }
        return true; // If all languages have at least one of them set to 1
    };

    // Handle the submit action
    const handleSubmit = async () => {
        if (!validateLanguages()) {
            showNotification(
                "Error",
                "Please ensure that at least one value (Read, Write, or Speak) is enabled for each language.",
                "danger"
            );
            return;
        }
        setUpdating(true);
        try {
            const response = await api.put("/api/update-user-language", {
                languages: modifiedLanguages,
            });
            if (response.status === 200) {
                showNotification(
                    "Success",
                    "Your language has been updated successfully!",
                    "success"
                );
                setUpdating(false);
                window.open(`/tasker-dashboard`, "_self");
            } else {
                showNotification("Error", "Error updating languages", "danger");
            }
        } catch (error) {
            setUpdating(false);
            let errorMsg =
                error.response?.data?.message || "Error in updating user language";
            showNotification("Error", errorMsg, "danger");
        }
    };
    return (
        <div className="update-language-container">
            {isError ? (
                <div
                    style={{
                        textAlign: "center",
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        width: "100%",
                        transform: "translate(-50%, -50%)",
                    }}
                >
                    <ErrorUI retry={() => fetchLanguages(sessionKey)} />
                </div>
            ) : null}
            {isLoading ? (
                <FullpageLoader
                    isOpen={isLoading}
                    message="Loading user language data... please wait"
                />
            ) : null}
            {isUpdating ? (
                <FullpageLoader
                    isOpen={isUpdating}
                    message="Updating user language data... please wait"
                />
            ) : null}
            {!isError && !isLoading && !isUpdating ? (
                <React.Fragment>
                    <h1>
                        There are no tasks available for your selected language, or you may
                        not have enabled 'Read & Speak' for it.
                    </h1>
                    <fieldset className="language-update-fieldset">
                        <legend>Update Language</legend>
                        {modifiedLanguages.length > 0 ? (
                            <ul className="language-list">
                                {modifiedLanguages.map((language) => (
                                    <li key={language.ID} className="language-item">
                                        <div className="language-info">
                                            <strong className="language-name">
                                                {language.Language}
                                            </strong>
                                        </div>

                                        <div className="checkbox-group">
                                            <label className="checkbox-label">
                                                Read
                                                <input
                                                    type="checkbox"
                                                    checked={language.isRead === 1}
                                                    onChange={(e) =>
                                                        handleCheckboxChange(
                                                            language.ID,
                                                            "isRead",
                                                            e.target.checked ? 1 : 0
                                                        )
                                                    }
                                                />
                                            </label>
                                            <label className="checkbox-label">
                                                Write
                                                <input
                                                    type="checkbox"
                                                    checked={language.isWrite === 1}
                                                    onChange={(e) =>
                                                        handleCheckboxChange(
                                                            language.ID,
                                                            "isWrite",
                                                            e.target.checked ? 1 : 0
                                                        )
                                                    }
                                                />
                                            </label>
                                            <label className="checkbox-label">
                                                Speak
                                                <input
                                                    type="checkbox"
                                                    checked={language.isSpeak === 1}
                                                    onChange={(e) =>
                                                        handleCheckboxChange(
                                                            language.ID,
                                                            "isSpeak",
                                                            e.target.checked ? 1 : 0
                                                        )
                                                    }
                                                />
                                            </label>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        ) : (
                            <div>No languages found.</div>
                        )}
                        {/* Submit Button */}
                        <div
                            style={{ width: "100%", textAlign: "center", marginTop: "24px" }}
                        >
                            <NextBtn
                                onClick={handleSubmit}
                                disabled={isUpdating}
                                style={{ width: "40%" }}
                            >
                                Submit
                            </NextBtn>
                        </div>
                    </fieldset>
                </React.Fragment>
            ) : null}
        </div>
    );
};
export default UpdateLanguageUI;