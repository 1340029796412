import React, { useEffect, useState } from "react";
import "./SiccaConversionCardCss.css";
import api from "../../api";

const SiccaConversionCard = ({ earningsData }) => {
  const [currencyData, setCurrencyData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState("");
  // const userNumber = localStorage.getItem("userNumber");
  const sessionKey = localStorage.getItem("sessionKey");

  useEffect(() => {
    const fetchCurrencyData = async () => {
      try {
        setIsLoading(true);
        if (!sessionKey) {
          window.open("/", "_self");
          return;
        }

        const response = await api.get(
          `/tasker-dashboard/user-currency-details`,
          {
            params: { sessionKey: sessionKey },
            headers: { "Cache-Control": "no-cache" },
          }
        );
        setCurrencyData(response.data);
      } catch (err) {
        console.error("Error fetching currency data:", err);
        if (err.response?.status === 404) {
          // window.open("/", "_self");
        } else {
          setError("Failed to load currency data");
        }
      } finally {
        setIsLoading(false);
      }
    };

    fetchCurrencyData();
  }, [sessionKey]);

  if (isLoading) return <div className="loading">Loading currency data...</div>;
  if (error) return <div className="error">{error}</div>;
  if (!currencyData) return null;

  // Updated conversion logic
  const calculateCurrency = (siccaAmount) => {
    const amount = Number(siccaAmount);
    // If 100 sicca = 1 INR (conversionRatio), then for any amount:
    // (amount * conversionRatio) / sicca base unit (100)
    return (
      (amount * currencyData.conversionRatio) /
      currencyData.sicca
    ).toFixed(2);
  };

  const formatSicca = (amount) => {
    return Number(amount).toFixed(2);
  };

  return (
    <div className="card sicca-card">
      <h2 className="section-title">Tasker Sicca to Currency Conversion</h2>

      <div className="conversion-info">
        <div className="info-item">
          <span className="info-label">Your currency</span>
          <span className="info-value">
            {currencyData.currencyCode} ({currencyData.currencyName})
          </span>
        </div>
        <div className="info-item">
          <span className="info-label">Conversion ratio</span>
          <span className="info-value">
            {currencyData.sicca} Tasker Sicca = {currencyData.conversionRatio}{" "}
            {currencyData.currencyCode}
          </span>
        </div>
      </div>

      <div className="conversion-table">
        <div className="table-header">
          <div className="header-cell">Earnings Status</div>
          <div className="header-cell text-right">Tasker Sicca</div>
          <div className="header-cell text-right">
            {currencyData.currencyCode}
          </div>
        </div>

        <div className="table-body">
          {/* Current Balance Row */}
          <div className="table-row">
            <div className="table-cell">Current Balance</div>
            <div className="table-cell text-right">
              {formatSicca(earningsData.currentBalance)}
            </div>
            <div className="table-cell text-right">
              {calculateCurrency(earningsData.currentBalance)}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SiccaConversionCard;
