// components/PaymentForm.js
import React, { useState, useEffect } from "react";
import FileUpload from "./FileUpload";
import "./TaskerPaymentFormStyles.css";
import api from "../../api";
import { showNotification } from "../../actions/index.actions";
import PaymentUpdateModal from "./PaymentUpdateModal";

const PaymentForm = () => {
  // Form Data State
  const [formData, setFormData] = useState({
    TaskerId: 0,
    Tasker_Name: "",
    TaskerMobile: "",
    idType: "",
    AadharNumber: "",
    PANNumber: "",
    paymentMethod: "BANK",
    UpiId: "",
    BankName: "",
    AccNo: "",
    IFSCcode: "",
  });

  // Field Error State
  const [fieldErrors, setFieldErrors] = useState({
    Tasker_Name: "",
    UpiId: "",
    IFSCcode: "",
    AadharNumber: "",
    PANNumber: "",
    AccNo: "",
    BankName: "",
  });

  // File Error State
  const [fileErrors, setFileErrors] = useState({
    AadharFrontImage: "",
    AadharBackImage: "",
    PANImage: "",
    BankPassBookImage: "",
  });

  const formatPatterns = {
    UpiId: {
      pattern: /^[\w.-]+@[\w.-]+$/i, // Case insensitive
      hint: "Format: username@bankname (e.g., john@okaxis)",
    },
    IFSCcode: {
      pattern: /^[A-Za-z]{4}0[A-Za-z0-9]{6}$/i, // Case insensitive
      hint: "Format: ABCD0123456 (11 characters)",
    },
    AadharNumber: {
      pattern: /^\d{12}$/,
      hint: "12 digits number",
    },
    PANNumber: {
      pattern: /^[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}$/i, // Case insensitive
      hint: "Format: ABCDE1234F (10 characters)",
    },
    AccNo: {
      pattern: /^\d{9,18}$/,
      hint: "9-18 digits number",
    },
  };

  // Other States
  const [userExists, setUserExists] = useState(true);
  const [otpError, setOtpError] = useState("");
  const [files, setFiles] = useState({
    AadharFrontImage: null,
    AadharBackImage: null,
    PANImage: null,
    BankPassBookImage: null,
  });
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState("");
  const [submitStatus, setSubmitStatus] = useState("");
  const [showOtpInput, setShowOtpInput] = useState(false);
  const [otp, setOtp] = useState("");
  const [resendTimer, setResendTimer] = useState(0);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [verificationStatus, setVerificationStatus] = useState("");

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState("");

  const [paymentDetailsId, setPaymentDetailsId] = useState(null);

  useEffect(() => {
    if (error) {
      window.scrollTo(0, 0);
    }
  }, [error]);

  const validateField = (name, value) => {
    switch (name) {
      case "Tasker_Name":
        if (!value.trim()) return "Name is required";
        if (value.length < 2) return "Name must be at least 2 characters";
        break;

      case "UpiId":
        if (formData.paymentMethod === "UPI") {
          if (!value) return "UPI ID is required";
          if (!formatPatterns.UpiId.pattern.test(value)) {
            return "Invalid UPI ID format";
          }
        }
        break;

      case "IFSCcode":
        if (formData.paymentMethod === "BANK") {
          if (!value) return "IFSC code is required";
          if (!formatPatterns.IFSCcode.pattern.test(value)) {
            return "Invalid IFSC code format";
          }
        }
        break;

      case "AadharNumber":
        if (value) {
          if (!formatPatterns.AadharNumber.pattern.test(value)) {
            return "Invalid Aadhar number format";
          }
        } else if (formData.paymentMethod === "UPI" && !formData.PANNumber) {
          return "Either Aadhar or PAN is required";
        }
        break;

      case "PANNumber":
        if (
          formData.paymentMethod === "BANK" ||
          (value && formData.paymentMethod === "UPI")
        ) {
          if (!value) return "PAN number is required";
          if (!formatPatterns.PANNumber.pattern.test(value)) {
            return "Invalid PAN number format";
          }
        }
        break;

      case "BankName":
        if (formData.paymentMethod === "BANK") {
          if (!value.trim()) return "Bank name is required";
          if (value.length < 3) return "Please enter valid bank name";
        }
        break;

      case "AccNo":
        if (formData.paymentMethod === "BANK") {
          if (!value.trim()) return "Account number is required";
          if (!formatPatterns.AccNo.pattern.test(value)) {
            return "Account number should be between 9 and 18 digits";
          }
        }
        break;

      default:
        return "";
    }
    return "";
  };

  // Fetch User Details Effect

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        //const userNumber = localStorage.getItem("userNumber");
        const sessionKey = localStorage.getItem("sessionKey");

        if (!sessionKey) {
          // console.log("No user number found, redirecting to login");
          window.open("/", "_self");
          return;
        }

        const response = await api.get("/tasker-dashboard/user-details", {
          params: { sessionKey: sessionKey },
          headers: { "Cache-Control": "no-cache" },
        });

        // Extract data properly from the response
        const userData = response.data.data;

        // Build full name, ensuring each part exists
        const nameParts = [
          userData.FirstName || "",
          userData.MiddleName || "",
          userData.LastName || "",
        ].filter((part) => part.trim() !== "");

        const fullName = nameParts.join(" ");

        // Update form data with proper values
        const updatedFormData = {
          ...formData,
          TaskerId: userData.ID,
          Tasker_Name: fullName,
          TaskerMobile: userData.Number,
        };

        setFormData(updatedFormData);
        setUserExists(true); // User was found
      } catch (err) {
        const errorMessage =
          err.response?.data?.message || "Failed to fetch user details";

        setError(errorMessage);
        setUserExists(false); // User was not found
      } finally {
        setLoading(false);
      }
    };

    fetchUserDetails();
  }, []);

  // Resend Timer Effect
  useEffect(() => {
    let timer;
    if (resendTimer > 0) {
      timer = setInterval(() => {
        setResendTimer((prev) => prev - 1);
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [resendTimer]);

  // Form Input Handler
  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "otp") {
      setOtp(value);
      return;
    }

    // Payment Method Change
    if (name === "paymentMethod") {
      setFormData((prev) => ({
        ...prev,
        [name]: value,
        UpiId: "",
        BankName: "",
        AccNo: "",
        IFSCcode: "",
        AadharNumber: "",
        PANNumber: "",
        idType: "",
      }));
      setFiles({
        AadharFrontImage: null,
        AadharBackImage: null,
        PANImage: null,
        BankPassBookImage: null,
      });
      setFieldErrors({
        Tasker_Name: "",
        UpiId: "",
        IFSCcode: "",
        AadharNumber: "",
        PANNumber: "",
        AccNo: "",
        BankName: "",
      });
      setFileErrors({
        AadharFrontImage: "",
        AadharBackImage: "",
        PANImage: "",
        BankPassBookImage: "",
      });
      return;
    }

    // Transform UPI ID to lowercase
    const transformedValue = name === "UpiId" ? value.toLowerCase() : value;

    // Handle ID document fields
    if (name === "AadharNumber" || name === "PANNumber") {
      const isAadhar = name === "AadharNumber";
      setFormData((prev) => ({
        ...prev,
        [name]: transformedValue,
        ...(formData.paymentMethod === "UPI" && {
          [isAadhar ? "PANNumber" : "AadharNumber"]: "",
          idType: isAadhar ? "AADHAR" : "PAN",
        }),
      }));

      if (formData.paymentMethod === "UPI") {
        setFiles((prev) => ({
          ...prev,
          ...(isAadhar
            ? { PANImage: null }
            : { AadharFrontImage: null, AadharBackImage: null }),
        }));
      }
    } else {
      setFormData((prev) => ({
        ...prev,
        [name]: transformedValue,
      }));
    }

    // Validate field
    const error = validateField(name, transformedValue);
    setFieldErrors((prev) => ({
      ...prev,
      [name]: error,
    }));
  };

  // File Upload Handler
  const validateFile = (file, type) => {
    const maxSize = 10 * 1024 * 1024; // 10MB
    const allowedTypes = ["image/jpeg", "image/jpg", "image/png"];

    if (file.size > maxSize) {
      return `${type}: File size must be less than 10MB`;
    }

    if (!allowedTypes.includes(file.type.toLowerCase())) {
      return `${type}: Only JPG, JPEG and PNG files are allowed`;
    }

    return null;
  };

  const handleFileChange = (type, file) => {
    if (file) {
      const error = validateFile(file, type);
      if (error) {
        setFileErrors((prev) => ({
          ...prev,
          [type]: error,
        }));
        return;
      }

      setFileErrors((prev) => ({
        ...prev,
        [type]: "",
      }));
    }

    setFiles((prev) => ({
      ...prev,
      [type]: file,
    }));
  };

  // Form Validation
  const validateForm = () => {
    if (!formData.Tasker_Name.trim()) {
      return "Name is required";
    }

    if (!formData.paymentMethod) {
      return "Please select a payment method";
    }

    // UPI Payment Validation
    if (formData.paymentMethod === "UPI") {
      if (!formData.UpiId?.trim()) {
        return "UPI ID is required";
      }
      if (!/^[\w.-]+@[\w.-]+$/.test(formData.UpiId)) {
        return "Please enter a valid UPI ID";
      }

      const hasAadhar =
        formData.AadharNumber &&
        files.AadharFrontImage &&
        files.AadharBackImage;
      const hasPAN = formData.PANNumber && files.PANImage;

      if (!hasAadhar && !hasPAN) {
        return "For UPI payment, either Aadhar (with both images) or PAN (with image) is required";
      }

      if (
        formData.AadharNumber ||
        files.AadharFrontImage ||
        files.AadharBackImage
      ) {
        if (!/^\d{12}$/.test(formData.AadharNumber)) {
          return "Please enter a valid 12-digit Aadhar number";
        }
        if (!files.AadharFrontImage || !files.AadharBackImage) {
          return "Both front and back Aadhar images are required";
        }
      }

      if (formData.PANNumber || files.PANImage) {
        if (!formatPatterns.PANNumber.pattern.test(formData.PANNumber)) {
          return "Please enter a valid PAN number";
        }
        if (!files.PANImage) {
          return "PAN card image is required";
        }
      }
    }

    // Bank Account Validation
    if (formData.paymentMethod === "BANK") {
      // Mandatory PAN validation
      if (!formatPatterns.PANNumber.pattern.test(formData.PANNumber)) {
        return "Please enter a valid PAN number (required for bank account)";
      }
      if (!files.PANImage) {
        return "PAN card image is required for bank account";
      }

      // Optional Aadhar validation
      if (
        formData.AadharNumber ||
        files.AadharFrontImage ||
        files.AadharBackImage
      ) {
        if (!/^\d{12}$/.test(formData.AadharNumber)) {
          return "Please enter a valid 12-digit Aadhar number";
        }
        if (!files.AadharFrontImage || !files.AadharBackImage) {
          return "If providing Aadhar, both front and back images are required";
        }
      }

      // Bank details validation
      if (!formData.BankName?.trim()) return "Bank name is required";
      if (!formData.AccNo?.trim()) return "Account number is required";
      if (!formatPatterns.IFSCcode.pattern.test(formData.IFSCcode)) {
        return "Please enter a valid IFSC code";
      }
      if (!files.BankPassBookImage) {
        return "Bank passbook image is required";
      }
    }

    return null;
  };

  // OTP Handlers
  const handleSendOtp = async () => {
    try {
      const { data } = await api.post(
        "/tasker-dashboard/payment-verification/send-otp",
        {
          number: formData.TaskerMobile,
          taskerId: formData.TaskerId,
        },
        {
          headers: { "Cache-Control": "no-cache" },
        }
      );

      setResendTimer(120);
      showNotification("Success", "OTP sent successfully!", "success");
    } catch (err) {
      console.error("OTP send error:", err);
      showNotification(
        "Error",
        err.response?.data?.message || "Failed to send OTP. Please try again.",
        "danger"
      );
    }
  };

  const handleVerifyOtp = async () => {
    try {
      setSubmitting(true);

      const { data } = await api.post(
        "/tasker-dashboard/payment-verification/verify-otp",
        {
          number: formData.TaskerMobile,
          otp: otp,
          paymentDetailsId: paymentDetailsId,
        },
        {
          headers: { "Cache-Control": "no-cache" },
        }
      );

      setVerificationStatus("success");
      setShowOtpInput(false);
      setSubmitStatus("verified");

      showNotification(
        "Success",
        "Payment details verified successfully! Redirecting to dashboard...",
        "success"
      );

      // Redirect after brief delay
      setTimeout(() => {
        window.location.href = "/tasker-earning-dashboard";
      }, 2000);
    } catch (err) {
      console.error("OTP verification error:", err);
      showNotification(
        "Error",
        err.response?.data?.message || "Invalid OTP. Please try again.",
        "danger"
      );
      setOtp("");
    } finally {
      setSubmitting(false);
    }
  };

  // Form Submit Handler
  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitting(true);

    try {
      const validationError = validateForm();
      if (validationError) {
        showNotification("Validation Error", validationError, "danger");
        setSubmitting(false);
        return;
      }

      // Prepare form data with properly capitalized values
      const preparedFormData = {
        ...formData,
        IFSCcode: formData.IFSCcode?.toUpperCase(),
        PANNumber: formData.PANNumber?.toUpperCase(),
        UpiId: formData.UpiId?.toLowerCase(),
      };

      // Upload files
      const fileFormData = new FormData();
      Object.entries(files).forEach(([key, file]) => {
        if (file) fileFormData.append(key, file);
      });
      fileFormData.append("paymentMethod", preparedFormData.paymentMethod);

      const { data: uploadedFiles } = await api.post(
        "/api/protected/file/upload/kyc-documents",
        fileFormData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      // Submit form data
      const finalFormData = {
        ...preparedFormData,
        ...uploadedFiles.files,
      };

      const { data } = await api.post(
        "/tasker-dashboard/add-payment-details",
        finalFormData,
        {
          headers: { "Cache-Control": "no-cache" },
        }
      );

      setPaymentDetailsId(data.id);

      // Show appropriate message based on whether it was an update or new entry
      const notificationMessage = data.isUpdate
        ? `Your existing payment details have been updated successfully. ${
            data.previouslyVerified
              ? " You'll need to verify your mobile number again"
              : ""
          }`
        : "Payment details submitted successfully! Please verify your mobile number.";

      if (data.isUpdate) {
        setModalMessage(notificationMessage);
        setIsModalOpen(true);
      } else {
        showNotification("Success", notificationMessage, "success");
      }

      setSubmitStatus("success");
      setFormSubmitted(true);
      setShowOtpInput(true);
      await handleSendOtp();
    } catch (err) {
      console.error("Form submission error:", err);
      alert('error', err)
      showNotification(
        "Error",
        err.response?.data?.message || "Failed to submit form",
        "danger"
      );
    } finally {
      setSubmitting(false);
    }
  };

  if (loading) {
    return <div className="loading">Loading...</div>;
  }

  if (!userExists) {
    return (
      <div className="payment-form-wrapper">
        <div className="payment-form-container">
          <div
            className="error-message"
            style={{ textAlign: "center", padding: "2rem" }}
          >
            <h2>User Not Found</h2>
            <p>{error}</p>
            <button
              onClick={() => window.open("/", "_self")}
              style={{
                marginTop: "1rem",
                padding: "0.5rem 1rem",
                backgroundColor: "#007bff",
                color: "white",
                border: "none",
                borderRadius: "4px",
                cursor: "pointer",
              }}
            >
              Go to Login
            </button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="payment-form-wrapper">
      <div className="payment-form-container">
        <h1>Payment Details</h1>

        {error && <div className="error-message">{error}</div>}
        {submitStatus === "success" && (
          <div className="success-message">
            Payment details submitted successfully! Please verify your mobile
            number.
          </div>
        )}
        {submitStatus === "verified" && (
          <div className="success-message">
            <h3>🎉 Verification Complete!</h3>
            <p>
              Your payment details have been successfully verified and saved.
            </p>
          </div>
        )}

        <form onSubmit={handleSubmit} className="payment-form">
          {/* Personal Information Section */}
          <section className="form-section">
            <h2>Personal Information</h2>
            <div className="form-row">
              <div className="form-group">
                <label htmlFor="Tasker_Name">
                  Full Name <span className="required">*</span>
                </label>
                <input
                  type="text"
                  id="Tasker_Name"
                  name="Tasker_Name"
                  value={formData.Tasker_Name}
                  onChange={handleInputChange}
                  required
                  className={`form-input readonly `}
                  readOnly
                  // readOnly={formSubmitted}
                  // disabled={formSubmitted}
                />
                {fieldErrors.Tasker_Name && (
                  <span className="field-error">{fieldErrors.Tasker_Name}</span>
                )}
              </div>
              <div className="form-group">
                <label htmlFor="TaskerMobile">Mobile Number</label>
                <input
                  type="text"
                  id="TaskerMobile"
                  value={formData.TaskerMobile}
                  readOnly
                  className="form-input readonly"
                />
              </div>
            </div>
          </section>

          {/* Payment Method Selection Section */}
          {/* <section className="form-section">
            <h2>Payment Method</h2>
            <div className="form-group">
              <label>
                Select Payment Method <span className="required">*</span>
              </label>
              <div className="payment-method-options">
                <label className="radio-label">
                  <input
                    type="radio"
                    name="paymentMethod"
                    value="UPI"
                    checked={formData.paymentMethod === "UPI"}
                    onChange={handleInputChange}
                    disabled={formSubmitted}
                  />
                  UPI
                </label>
                <label className="radio-label">
                  <input
                    type="radio"
                    name="paymentMethod"
                    value="BANK"
                    checked={formData.paymentMethod === "BANK"}
                    onChange={handleInputChange}
                    disabled={formSubmitted}
                  />
                  Bank Account
                </label>
              </div>
            </div>
          </section> */}

          {formData.paymentMethod === "UPI" && (
            <>
              {/* UPI Payment Details */}
              <section className="form-section">
                <h2>UPI Details</h2>
                <div className="form-group">
                  <label htmlFor="UpiId">
                    UPI ID <span className="required">*</span>
                  </label>
                  <input
                    type="text"
                    id="UpiId"
                    name="UpiId"
                    value={formData.UpiId}
                    onChange={handleInputChange}
                    placeholder="example@upi"
                    className="form-input lowercase" // Added lowercase class
                    required
                    readOnly={formSubmitted}
                    disabled={formSubmitted}
                  />
                  {fieldErrors.UpiId && (
                    <span className="field-error">{fieldErrors.UpiId}</span>
                  )}
                  {formatPatterns.UpiId?.hint && (
                    <span className="field-hint">
                      {formatPatterns.UpiId.hint}
                    </span>
                  )}
                </div>
              </section>

              {/* ID Verification for UPI */}
              <section className="form-section">
                <h2>ID Verification</h2>
                <p className="verification-note">
                  Please provide either Aadhar or PAN details
                </p>

                {/* Aadhar Section */}
                <div className="id-section">
                  <div className="aadhar-section">
                    <div className="form-group">
                      <label htmlFor="AadharNumber">Aadhar Number</label>
                      <input
                        type="text"
                        id="AadharNumber"
                        name="AadharNumber"
                        value={formData.AadharNumber}
                        onChange={handleInputChange}
                        pattern="\d{12}"
                        maxLength={12}
                        placeholder="12-digit Aadhar number"
                        className={`form-input `}
                        disabled={formSubmitted || !!formData.PANNumber}
                      />
                      {fieldErrors.AadharNumber && (
                        <span className="field-error">
                          {fieldErrors.AadharNumber}
                        </span>
                      )}
                      {formatPatterns.AadharNumber?.hint && (
                        <span className="field-hint">
                          {formatPatterns.AadharNumber.hint}
                        </span>
                      )}
                    </div>
                    <div className="documents-grid">
                      <div className="file-upload-container">
                        <FileUpload
                          label="Aadhar Front"
                          onChange={(file) =>
                            handleFileChange("AadharFrontImage", file)
                          }
                          onRemove={() =>
                            handleFileChange("AadharFrontImage", null)
                          }
                          disabled={formSubmitted || !!formData.PANNumber}
                        />
                        {fileErrors.AadharFrontImage && (
                          <span className="file-error">
                            {fileErrors.AadharFrontImage}
                          </span>
                        )}
                      </div>
                      <div className="file-upload-container">
                        <FileUpload
                          label="Aadhar Back"
                          onChange={(file) =>
                            handleFileChange("AadharBackImage", file)
                          }
                          onRemove={() =>
                            handleFileChange("AadharBackImage", null)
                          }
                          disabled={formSubmitted || !!formData.PANNumber}
                        />
                        {fileErrors.AadharBackImage && (
                          <span className="file-error">
                            {fileErrors.AadharBackImage}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="section-divider">
                  <span className="divider-text">or</span>
                </div>

                {/* PAN Section */}
                <div className="id-section">
                  <div className="form-group">
                    <label htmlFor="PANNumber">PAN Number</label>
                    <input
                      type="text"
                      id="PANNumber"
                      name="PANNumber"
                      value={formData.PANNumber}
                      onChange={handleInputChange}
                      maxLength={10}
                      placeholder="10-digit PAN number"
                      className={`form-input uppercase  `}
                      disabled={formSubmitted || !!formData.AadharNumber}
                    />
                    {fieldErrors.PANNumber && (
                      <span className="field-error">
                        {fieldErrors.PANNumber}
                      </span>
                    )}
                    {formatPatterns.PANNumber?.hint && (
                      <span className="field-hint">
                        {formatPatterns.PANNumber.hint}
                      </span>
                    )}
                  </div>
                  <div className="file-upload-container">
                    <FileUpload
                      label="PAN Card"
                      onChange={(file) => handleFileChange("PANImage", file)}
                      onRemove={() => handleFileChange("PANImage", null)}
                      disabled={formSubmitted || !!formData.AadharNumber}
                    />
                    {fileErrors.PANImage && (
                      <span className="file-error">{fileErrors.PANImage}</span>
                    )}
                  </div>
                </div>
              </section>
            </>
          )}

          {formData.paymentMethod === "BANK" && (
            <>
              {/* Bank Account Details */}
              <section className="form-section">
                <h2>Bank Account Details</h2>
                <div className="form-row">
                  <div className="form-group">
                    <label htmlFor="BankName">
                      Bank Name <span className="required">*</span>
                    </label>
                    <input
                      type="text"
                      id="BankName"
                      name="BankName"
                      value={formData.BankName}
                      onChange={handleInputChange}
                      placeholder="Enter bank name"
                      className={`form-input uppercase `}
                      required
                      readOnly={formSubmitted}
                    />
                    {fieldErrors.BankName && (
                      <span className="field-error">
                        {fieldErrors.BankName}
                      </span>
                    )}
                  </div>
                  <div className="form-group">
                    <label htmlFor="AccNo">
                      Account Number <span className="required">*</span>
                    </label>
                    <input
                      type="text"
                      id="AccNo"
                      name="AccNo"
                      value={formData.AccNo}
                      onChange={handleInputChange}
                      placeholder="Enter account number"
                      className={`form-input`}
                      required
                      readOnly={formSubmitted}
                    />
                    {fieldErrors.AccNo && (
                      <span className="field-error">{fieldErrors.AccNo}</span>
                    )}
                    {formatPatterns.AccNo?.hint && (
                      <span className="field-hint">
                        {formatPatterns.AccNo.hint}
                      </span>
                    )}
                  </div>
                  {/* Removed duplicate IFSC input and updated remaining one */}
                  <div className="form-group">
                    <label htmlFor="IFSCcode">
                      IFSC Code <span className="required">*</span>
                    </label>
                    <input
                      type="text"
                      id="IFSCcode"
                      name="IFSCcode"
                      value={formData.IFSCcode}
                      onChange={handleInputChange}
                      placeholder="Enter IFSC code"
                      className={`form-input uppercase `}
                      required
                      readOnly={formSubmitted}
                    />
                    {fieldErrors.IFSCcode && (
                      <span className="field-error">
                        {fieldErrors.IFSCcode}
                      </span>
                    )}
                    {formatPatterns.IFSCcode?.hint && (
                      <span className="field-hint">
                        {formatPatterns.IFSCcode.hint}
                      </span>
                    )}
                  </div>
                </div>
                <div className="form-group">
                  <FileUpload
                    label="Bank Passbook"
                    onChange={(file) =>
                      handleFileChange("BankPassBookImage", file)
                    }
                    onRemove={() => handleFileChange("BankPassBookImage", null)}
                    disabled={formSubmitted}
                  />
                  {fileErrors.BankPassBookImage && (
                    <span className="file-error">
                      {fileErrors.BankPassBookImage}
                    </span>
                  )}
                </div>
              </section>

              {/* ID Verification Section */}
              <section className="form-section">
                <h2>ID Verification</h2>

                {/* PAN Details (Required) */}
                <div className="id-section">
                  <div className="form-group">
                    <label htmlFor="PANNumber">
                      PAN Number <span className="required">*</span>
                    </label>
                    <input
                      type="text"
                      id="PANNumber"
                      name="PANNumber"
                      value={formData.PANNumber}
                      onChange={handleInputChange}
                      maxLength={10}
                      placeholder="10-digit PAN number"
                      className={`form-input uppercase  `}
                      required
                      readOnly={formSubmitted}
                    />
                    {fieldErrors.PANNumber && (
                      <span className="field-error">
                        {fieldErrors.PANNumber}
                      </span>
                    )}
                    {formatPatterns.PANNumber?.hint && (
                      <span className="field-hint">
                        {formatPatterns.PANNumber.hint}
                      </span>
                    )}
                  </div>
                  <div className="file-upload-container">
                    <FileUpload
                      label="PAN Card"
                      onChange={(file) => handleFileChange("PANImage", file)}
                      onRemove={() => handleFileChange("PANImage", null)}
                      disabled={formSubmitted}
                    />
                    {fileErrors.PANImage && (
                      <span className="file-error">{fileErrors.PANImage}</span>
                    )}
                  </div>
                </div>

                {/* Optional Aadhar Section */}
                <div className="section-divider">
                  <span className="divider-text">Optional</span>
                </div>

                <div className="id-section">
                  <div className="aadhar-section">
                    <div className="form-group">
                      <label htmlFor="AadharNumber">
                        Aadhar Number (Optional)
                      </label>
                      <input
                        type="text"
                        id="AadharNumber"
                        name="AadharNumber"
                        value={formData.AadharNumber}
                        onChange={handleInputChange}
                        pattern="\d{12}"
                        maxLength={12}
                        placeholder="12-digit Aadhar number"
                        className={`form-input`}
                        disabled={formSubmitted}
                      />
                      {fieldErrors.AadharNumber && (
                        <span className="field-error">
                          {fieldErrors.AadharNumber}
                        </span>
                      )}
                      {formatPatterns.AadharNumber?.hint && (
                        <span className="field-hint">
                          {formatPatterns.AadharNumber.hint}
                        </span>
                      )}
                    </div>
                    <div className="documents-grid">
                      <div className="file-upload-container">
                        <FileUpload
                          label="Aadhar Front"
                          onChange={(file) =>
                            handleFileChange("AadharFrontImage", file)
                          }
                          onRemove={() =>
                            handleFileChange("AadharFrontImage", null)
                          }
                          disabled={formSubmitted}
                        />
                        {fileErrors.AadharFrontImage && (
                          <span className="file-error">
                            {fileErrors.AadharFrontImage}
                          </span>
                        )}
                      </div>
                      <div className="file-upload-container">
                        <FileUpload
                          label="Aadhar Back"
                          onChange={(file) =>
                            handleFileChange("AadharBackImage", file)
                          }
                          onRemove={() =>
                            handleFileChange("AadharBackImage", null)
                          }
                          disabled={formSubmitted}
                        />
                        {fileErrors.AadharBackImage && (
                          <span className="file-error">
                            {fileErrors.AadharBackImage}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </>
          )}

          {/* Submit/OTP Section */}
          {/* Submit/OTP Section */}
          <section className="form-section submit-section">
            <div className="form-actions">
              {!formSubmitted ? (
                <button
                  type="submit"
                  className="submit-button"
                  disabled={
                    submitting ||
                    Object.values(fieldErrors).some((error) => error) ||
                    Object.values(fileErrors).some((error) => error)
                  }
                >
                  {submitting ? (
                    <span className="button-content">
                      Processing... <span className="spinner"></span>
                    </span>
                  ) : (
                    "Submit and Verify Mobile Number"
                  )}
                </button>
              ) : (
                <div className="otp-section">
                  <div className="form-group">
                    <label htmlFor="otp">
                      Enter OTP sent to {formData.TaskerMobile}
                    </label>
                    <input
                      type="text"
                      id="otp"
                      name="otp"
                      value={otp}
                      onChange={handleInputChange}
                      className="form-input"
                      maxLength={6}
                      placeholder="Enter 6-digit OTP"
                      disabled={submitStatus === "verified"}
                      pattern="\d{6}"
                    />
                    {otpError && (
                      <div className="otp-error-message">
                        <p className="error-text">{otpError}</p>
                        <p className="retry-message">
                          Please check the OTP and try again
                        </p>
                      </div>
                    )}
                  </div>

                  {submitStatus !== "verified" && (
                    <div className="otp-actions">
                      <button
                        type="button"
                        onClick={handleVerifyOtp}
                        className={`submit-button ${
                          otp.length !== 6 ? "disabled" : ""
                        }`}
                        disabled={submitting || otp.length !== 6}
                      >
                        {submitting ? (
                          <span className="button-content">
                            Verifying... <span className="spinner"></span>
                          </span>
                        ) : (
                          "Verify OTP"
                        )}
                      </button>

                      {resendTimer > 0 ? (
                        <p className="resend-timer">
                          <span className="timer-icon">⏱️</span>
                          Resend OTP in {resendTimer} seconds
                        </p>
                      ) : (
                        <button
                          type="button"
                          onClick={handleSendOtp}
                          className="resend-button"
                          disabled={submitStatus === "verified"}
                        >
                          Resend OTP
                        </button>
                      )}
                    </div>
                  )}
                </div>
              )}
            </div>
          </section>
        </form>
      </div>

      <PaymentUpdateModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        message={modalMessage}
      />
    </div>
  );
};

export default PaymentForm;
