import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import "./index.css";
import App from "./App";
import store from "./store"; // Import your store
import reportWebVitals from "./reportWebVitals";
import { ReactNotifications } from "react-notifications-component";

const root = ReactDOM.createRoot(document.getElementById("root"));
// Disable right-click (context menu)
// document.addEventListener('contextmenu', (e) => {
//   e.preventDefault();
// });
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <ReactNotifications />
      <App />
    </Provider>
  </React.StrictMode>
);
reportWebVitals();
