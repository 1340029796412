// RatingsDashboard.js
import React, { useState, useEffect } from "react";
import "./UserRatingsDashboardStyles.css";
import api from "../../api";
import TaskDetailsModal from "./TaskDetailsModal";
import moment from "moment";

const TimeRangeSelector = ({ value, onChange }) => (
  <div className="time-range">
    <div className="select-wrapper">
      <select value={value} onChange={(e) => onChange(e.target.value)}>
        <option value="30">last 30 days</option>
        <option value="60">last 60 days</option>
        <option value="90">last 90 days</option>
      </select>
    </div>
  </div>
);

const StatsBar = ({ stats }) => (
  <div className="stats-bar">
    <div className="stat-item">
      <div className="stat-value">{stats.avgRating?.toFixed(1) || "0.0"}</div>
      <div className="stat-label">Avg. Rating</div>
    </div>
    <div className="divider"></div>
    <div className="stat-item">
      <div className="stat-value">{stats.pendingCount}</div>
      <div className="stat-label">pending review</div>
    </div>
    <div className="divider"></div>
    <div className="stat-item">
      <div className="stat-value">{`${stats.acceptedCount}/${stats.rejectedCount}`}</div>
      <div className="stat-label">accepted/rejected</div>
    </div>
  </div>
);

const TaskCard = ({ task, onClick }) => (
  <div className="task-card" onClick={onClick} style={{ cursor: "pointer" }}>
    <div className="task-header">
      <div className="task-meta">
        <span className="task-id">#{task.id}</span>
        <span className={`status-badge ${task.status}`}>{task.status}</span>
        <div className="ratings-list">
          {Object.entries(task.ratings || {}).map(([key, value]) => (
            <span key={key} className="rating-badge">
              {key}: {value}
            </span>
          ))}
        </div>
      </div>
    </div>
    <div className="task-comments">
      <p className="comment-text">
        <span className="comment-label">Comment:</span> {task.comment}
        {task.additionalComment && ` ${task.additionalComment}`}
      </p>
    </div>
  </div>
);

const Pagination = ({ currentPage, totalPages, onPageChange }) => (
  <div className="pagination-wrapper">
    <div className="pagination">
      <button
        onClick={() => onPageChange(currentPage - 1)}
        disabled={currentPage === 1}
        className="page-button prev"
        aria-label="Previous page"
      />
      <span className="page-info">
        {currentPage} / {totalPages}
      </span>
      <button
        onClick={() => onPageChange(currentPage + 1)}
        disabled={currentPage === totalPages}
        className="page-button next"
        aria-label="Next page"
      />
    </div>
  </div>
);

const NotificationCard = ({ notification }) => (
  <div
    className={`notification-card task-card ${notification.readAt ? "read" : "unread"
      }`}
  >
    <div className="notification-content">
      <p className="notification-message">
        <span className="comment-label">Message:</span> {notification.message}
      </p>
      {notification.additionalMessage && (
        <p className="notification-additional">
          {notification.additionalMessage}
        </p>
      )}
      <p className="notification-date">
        <span className="comment-label">Date:</span>{" "}
        {/* {new Date(notification.createdAt).toLocaleString("en-IN")} */}
        {moment(notification.createdAt).format("DD/MM/YYYY, h:mm A")}
      </p>
    </div>
  </div>
);

const RatingsDashboard = () => {
  //const sessionKey = localStorage.getItem("userNumber");
  const sessionKey = localStorage.getItem("sessionKey");
  const [timeRange, setTimeRange] = useState("30");
  const [currentPage, setCurrentPage] = useState(1);
  const [tasks, setTasks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [totalPages, setTotalPages] = useState(1);
  const [stats, setStats] = useState({
    avgRating: 0,
    acceptedCount: 0,
    rejectedCount: 0,
    pendingCount: 0,
  });

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedTaskDetails, setSelectedTaskDetails] = useState(null);
  const [loadingTaskDetails, setLoadingTaskDetails] = useState(false);
  const [notificationsMarkedAsRead, setNotificationsMarkedAsRead] =
    useState(false);

  const [notifications, setNotifications] = useState("");

  const markNotificationsAsRead = async () => {
    try {
      const response = await api.post(
        "/tasker-dashboard/notifications/mark-as-read",
        {},
        {
          params: { sessionKey }, // params as part of the config object
          headers: { "Cache-Control": "no-cache" },
        }
      );

      if (response.status === 200) {
        setNotificationsMarkedAsRead(true);
      }
    } catch (err) {
      console.error("Error marking notifications as read:", err);
    }
  };

  const fetchNotifications = async () => {
    try {
      const response = await api.get("/tasker-dashboard/notifications", {
        params: { sessionKey },
        headers: { "Cache-Control": "no-cache" },
      });
      setNotifications(response.data); // Store the notification string

      // mark notification as done in 10 seconds
      if (response.data.length > 0 && !notificationsMarkedAsRead) {
        setTimeout(() => {
          markNotificationsAsRead();
        }, 5000); // 10 seconds
      }
    } catch (err) {
      console.error("Error fetching notifications:", err);
    }
  };

  const fetchTaskDetails = async (taskResultId) => {
    try {
      setLoadingTaskDetails(true);
      const response = await api.get(
        `/tasker-dashboard/task-details/${taskResultId}`
      );

      const data = response.data;
      const transformedData = {
        taskResultId: data.TaskResultId,
        voiceDescription: data.VoiceDescription,
        taskUrl: data.TaskUrl,
        timestamp: data.Timestamp,
      };

      setSelectedTaskDetails(transformedData);
    } catch (error) {
      console.error("Error fetching task details:", error);
    } finally {
      setLoadingTaskDetails(false);
    }
  };

  const handleTaskClick = async (taskId) => {
    setIsModalOpen(true);
    await fetchTaskDetails(taskId);
  };

  const fetchDashboardData = async () => {
    if (!sessionKey) {
      window.open("/", "_self");
      return;
    }

    try {
      setLoading(true);
      setError(null);

      const response = await api.get(
        `/tasker-dashboard/user-ratings-dashboard`,
        {
          params: {
            sessionKey: sessionKey,
            days: timeRange,
            page: currentPage,
            limit: 10,
          },
          headers: {
            "Cache-Control": "no-cache",
          },
        }
      );

      // Remove response.ok check since axios/api client handles errors differently
      const data = response.data; // Use response.data instead of response.json()

      //  console.log("Dashboard data received:", data);

      // Update stats - match the exact API response structure
      setStats({
        avgRating: data.statistics.averageRating,
        acceptedCount: data.statistics.accepted,
        rejectedCount: data.statistics.rejected,
        pendingCount: data.statistics.pending,
      });

      // Update tasks with proper mapping
      setTasks(
        data.taskDetails.tasks.map((task) => ({
          id: task.taskResultId,
          status: task.statusText,
          ratings: task.ratings?.reduce((acc, curr) => {
            acc[curr.criteriaName] = curr.rating;
            return acc;
          }, {}),
          comment: task.comments?.[0]?.selectedOption || "No comment",
          additionalComment: task.comments?.[0]?.additionalComment || "",
          timestamp: new Date(parseInt(task.timestamp)).toLocaleString(),
        }))
      );

      setTotalPages(data.taskDetails.totalPages);
    } catch (err) {
      console.error("Error fetching dashboard data:", err);
      setError(err.response?.data?.message || "Failed to load dashboard data");
      if (err.response?.status === 404) {
        window.open("/", "_self");
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDashboardData();
    fetchNotifications();
  }, [timeRange, currentPage]);

  const handleTimeRangeChange = (newRange) => {
    setTimeRange(newRange);
    setCurrentPage(1); // Reset to first page when changing time range
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  if (error) {
    return <div className="error-message">{error}</div>;
  }

  return (
    <div className="ratings-dashboard">
      <div className="dashboard-content">
        <h1 className="dashboard-title">Ratings</h1>

        <TimeRangeSelector value={timeRange} onChange={handleTimeRangeChange} />

        <StatsBar stats={stats} />

        {notifications.length > 0 && (
          <div className="notifications-section">
            <h2 className="section-title">Notifications:</h2>
            <div className="notifications-list">
              {notifications.map((notification) => (
                <NotificationCard
                  key={notification.notificationId}
                  notification={notification}
                />
              ))}
            </div>
          </div>
        )}

        <div className="tasks-section">
          <h2 className="section-title">Tasks:</h2>
          {loading ? (
            <div className="loading">Loading...</div>
          ) : tasks.length === 0 ? (
            <div className="no-tasks">No tasks found</div>
          ) : (
            <div className="tasks-list">
              {tasks.map((task) => (
                <TaskCard
                  key={task.id}
                  task={task}
                  onClick={() => handleTaskClick(task.id)}
                />
              ))}
            </div>
          )}
        </div>

        {!loading && tasks.length > 0 && (
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
          />
        )}
      </div>
      <TaskDetailsModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        taskDetails={selectedTaskDetails}
        loading={loadingTaskDetails}
      />
    </div>
  );
};

export default RatingsDashboard;

// old code:
// import React, { useState, useEffect } from "react";
// import Calendar from "react-calendar";
// import "react-calendar/dist/Calendar.css";
// import "./UserRatingsDashboardStyles.css";
// import TaskDetailsModal from "./TaskDetailsModal";
// import { format, startOfDay, endOfDay, parseISO, fromUnixTime } from "date-fns";
// import api from "../../api";

// const RatingDisplay = ({ rating }) => {
//   return <div className="rating-value">{rating.toFixed(1)} / 5.0</div>;
// };

// const RatingsDashboard = () => {
//   const userNumber = localStorage.getItem("userNumber");

//   const [selectedDate, setSelectedDate] = useState(null);
//   const [activeStartDate, setActiveStartDate] = useState(
//     startOfDay(new Date())
//   );
//   const [dashboardData, setDashboardData] = useState(null);
//   const [loading, setLoading] = useState(true);
//   const [isRangeSelection, setIsRangeSelection] = useState(false);
//   const [taskDates, setTaskDates] = useState(new Map());
//   const [taskType, setTaskType] = useState("");
//   const [minDate, setMinDate] = useState(null);
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const [selectedTaskDetails, setSelectedTaskDetails] = useState(null);
//   const [loadingTaskDetails, setLoadingTaskDetails] = useState(false);

//   const taskTypeOptions = [
//     { value: "type1", label: "Audio" },
//     { value: "type2", label: "Video" },
//     { value: "type3", label: "Image" },
//   ];

//   const maxDate = endOfDay(new Date());

//   const formatDateForApi = (date) => {
//     if (!date) return "";
//     return startOfDay(date).getTime().toString();
//   };

//   const formatEndDateForApi = (date) => {
//     if (!date) return "";
//     return endOfDay(date).getTime().toString();
//   };

//   const fetchTaskDates = async () => {
//     try {
//       const { data } = await api.get("/tasker-dashboard/user-task-dates", {
//         params: { sessionKey: userNumber },
//         headers: { "Cache-Control": "no-cache" },
//       });

//       const taskDateMap = new Map();
//       data.forEach(({ timestamp, taskCount }) => {
//         const date = new Date(parseInt(timestamp));
//         const dateKey = format(date, "yyyy-MM-dd");
//         const currentCount = taskDateMap.get(dateKey) || 0;
//         taskDateMap.set(dateKey, currentCount + taskCount);
//       });

//       setTaskDates(taskDateMap);
//     } catch (error) {
//       console.error("Error fetching task dates:", error);
//     }
//   };

//   const fetchTaskDetails = async (taskResultId) => {
//     try {
//       setLoadingTaskDetails(true);
//       const { data } = await api.get(
//         `/tasker-dashboard/task-details/${taskResultId}`,
//         {
//           headers: { "Cache-Control": "no-cache" },
//         }
//       );

//       const transformedData = {
//         taskResultId: data.TaskResultId,
//         voiceDescription: data.VoiceDescription,
//         taskUrl: data.TaskUrl,
//         timestamp: data.Timestamp,
//       };

//       setSelectedTaskDetails(transformedData);
//     } catch (error) {
//       console.error("Error fetching task details:", error);
//     } finally {
//       setLoadingTaskDetails(false);
//     }
//   };

//   const fetchDashboardData = async (startDate, endDate) => {
//     if (!startDate || !userNumber) return;

//     try {
//       setLoading(true);
//       const formattedStartDate = formatDateForApi(startDate);
//       const formattedEndDate = formatEndDateForApi(endDate || startDate);

//       const { data } = await api.get(
//         "/tasker-dashboard/user-ratings-dashboard",
//         {
//           params: {
//             sessionKey: userNumber,
//             startDate: formattedStartDate,
//             endDate: formattedEndDate,
//           },
//           headers: { "Cache-Control": "no-cache" },
//         }
//       );

//       if (!data) {
//         console.error("No data received from API");
//         return;
//       }

//       setDashboardData(data);

//       if (data.userMetadata?.createdAt) {
//         const createdAtDate = fromUnixTime(
//           Math.floor(parseInt(data.userMetadata.createdAt) / 1000)
//         );
//         setMinDate(startOfDay(createdAtDate));
//       }
//     } catch (error) {
//       if (error.response?.status === 404) {
//         window.open("/", "_self");
//         return;
//       }
//       console.error("Error fetching dashboard data:", error);
//     } finally {
//       setLoading(false);
//     }
//   };

//   useEffect(() => {
//     if (!userNumber) {
//       window.open("/", "_self");
//     }
//     const today = new Date();
//     fetchDashboardData(today, today);
//     fetchTaskDates();
//   }, []);

//   useEffect(() => {
//     if (!isRangeSelection && Array.isArray(selectedDate)) {
//       const singleDate = selectedDate[0];
//       setSelectedDate(singleDate);
//       fetchDashboardData(singleDate, singleDate);
//     } else if (
//       isRangeSelection &&
//       !Array.isArray(selectedDate) &&
//       selectedDate
//     ) {
//       setSelectedDate([selectedDate, selectedDate]);
//       fetchDashboardData(selectedDate, selectedDate);
//     }
//   }, [isRangeSelection]);

//   const handleDateChange = (date) => {
//     if (!date) return;

//     setSelectedDate(date);

//     if (isRangeSelection) {
//       if (Array.isArray(date)) {
//         const [start, end] = date;
//         if (end > maxDate) return;
//         fetchDashboardData(start, end);
//       }
//     } else {
//       fetchDashboardData(date, date);
//     }
//   };

//   const handleTaskClick = async (taskResultId) => {
//     setIsModalOpen(true);
//     await fetchTaskDetails(taskResultId);
//   };

//   const handleTodayClick = () => {
//     const today = startOfDay(new Date());
//     setSelectedDate(isRangeSelection ? [today, today] : today);
//     setActiveStartDate(today);
//     fetchDashboardData(today, today);
//   };

//   const handleClearClick = () => {
//     setSelectedDate(null);
//     setIsRangeSelection(false);
//     const today = startOfDay(new Date());
//     fetchDashboardData(today, today);
//   };

//   const handleActiveStartDateChange = ({ activeStartDate }) => {
//     if (activeStartDate) {
//       setActiveStartDate(startOfDay(activeStartDate));
//     }
//   };

//   const getTileClassName = ({ date, view }) => {
//     if (view !== "month") return null;

//     const dateKey = format(startOfDay(date), "yyyy-MM-dd");
//     const taskCount = taskDates.get(dateKey);

//     if (!taskCount) return null;

//     const classes = ["has-task"];
//     if (taskCount > 1) {
//       classes.push("has-multiple-tasks");
//     }

//     return classes.join(" ");
//   };

//   const handleModeToggle = () => {
//     setIsRangeSelection(!isRangeSelection);
//   };

//   return (
//     <div className="ratings-container">
//       <div className="ratings-dashboard">
//         <h1 className="dashboard-title">Ratings</h1>

//         <div className="calendar-wrapper">
//           <div className="calendar-header">
//             <div className="calendar-buttons">
//               <button
//                 onClick={handleTodayClick}
//                 className="today-button"
//                 type="button"
//               >
//                 Today
//               </button>

//               <button
//                 onClick={handleModeToggle}
//                 className={`range-toggle-button ${
//                   isRangeSelection ? "active" : ""
//                 }`}
//                 type="button"
//               >
//                 {isRangeSelection ? "Single Date" : "Date Range"}
//               </button>
//               <button
//                 onClick={handleClearClick}
//                 className="clear-button"
//                 type="button"
//               >
//                 Clear
//               </button>
//             </div>
//           </div>

//           <div className="calendar-instruction">
//             <span className="instruction-icon">*</span> Click on dates with blue
//             highlights to view your tasks
//           </div>

//           <Calendar
//             onChange={handleDateChange}
//             value={selectedDate}
//             activeStartDate={activeStartDate}
//             onActiveStartDateChange={handleActiveStartDateChange}
//             selectRange={isRangeSelection}
//             tileClassName={getTileClassName}
//             className="custom-calendar"
//             maxDate={maxDate}
//             minDate={minDate}
//             minDetail="month"
//           />
//         </div>

//         {loading ? (
//           <div className="loading-text">Loading...</div>
//         ) : (
//           dashboardData && (
//             <>
//               <div className="stats-section">
//                 <div className="stat-item task-type">
//                   <span>Task Type</span>
//                   <select
//                     disabled
//                     value={taskType}
//                     onChange={(e) => setTaskType(e.target.value)}
//                   >
//                     <option value="">All Types</option>
//                     {taskTypeOptions.map((option) => (
//                       <option key={option.value} value={option.value}>
//                         {option.label}
//                       </option>
//                     ))}
//                   </select>
//                 </div>
//                 <div className="stat-item rating">
//                   <span>Avg Rating</span>
//                   <span>
//                     {dashboardData.statistics.averageRating.toFixed(1)}
//                   </span>
//                 </div>
//                 <div className="stat-item accepted">
//                   <span>Accepted/Rejected</span>
//                   <span>
//                     {dashboardData.statistics.accepted}/
//                     {dashboardData.statistics.rejected}
//                   </span>
//                 </div>
//                 <div className="stat-item pending">
//                   <span>Pending</span>
//                   <span>{dashboardData.statistics.pending}</span>
//                 </div>
//               </div>

//               <div className="details-section">
//                 <h3>Task Details</h3>
//                 {dashboardData.taskDetails.length === 0 ? (
//                   <div className="no-data-message">
//                     <div className="no-data-content">
//                       <svg
//                         className="no-data-icon"
//                         fill="none"
//                         stroke="currentColor"
//                         viewBox="0 0 24 24"
//                         width="48"
//                         height="48"
//                       >
//                         <path
//                           strokeLinecap="round"
//                           strokeLinejoin="round"
//                           strokeWidth="2"
//                           d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V7a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
//                         />
//                       </svg>
//                       <h4>No Data Available</h4>
//                       <p>
//                         {isRangeSelection && Array.isArray(selectedDate)
//                           ? `No tasks found between ${format(
//                               selectedDate[0],
//                               "MMM dd, yyyy"
//                             )} and ${format(selectedDate[1], "MMM dd, yyyy")}`
//                           : selectedDate
//                           ? `No tasks found for ${format(
//                               selectedDate,
//                               "MMM dd, yyyy"
//                             )}`
//                           : "No tasks found for the selected date"}
//                       </p>
//                       {(!selectedDate ||
//                         (Array.isArray(selectedDate) &&
//                           format(selectedDate[0], "yyyy-MM-dd") !==
//                             format(new Date(), "yyyy-MM-dd"))) && (
//                         <button
//                           onClick={handleTodayClick}
//                           className="view-today-button"
//                           type="button"
//                         >
//                           View Today's Tasks
//                         </button>
//                       )}
//                     </div>
//                   </div>
//                 ) : (
//                   <div className="task-list-container">
//                     <table className="task-list">
//                       <thead>
//                         <tr>
//                           <th>Task ID</th>
//                           <th>Status</th>
//                           <th>Review</th>
//                         </tr>
//                       </thead>
//                       <tbody>
//                         {dashboardData.taskDetails.map((task) => (
//                           <tr
//                             key={task.taskResultId}
//                             onClick={() => handleTaskClick(task.taskResultId)}
//                             className="cursor-pointer hover:bg-gray-50"
//                           >
//                             <td>{task.taskResultId}</td>
//                             <td>
//                               <span
//                                 className={`status-badge ${
//                                   task.status === 1
//                                     ? "accepted"
//                                     : task.status === 6
//                                     ? "rejected"
//                                     : "pending"
//                                 }`}
//                               >
//                                 {task.statusText}
//                               </span>
//                             </td>
//                             <td>
//                               {task.status === 1 || task.status === 6 ? (
//                                 <div className="review-section">
//                                   <div className="review-section-title">
//                                     Reviews:
//                                   </div>
//                                   {task.ratings?.map((rating, index) => (
//                                     <div key={index} className="review-item">
//                                       <div className="review-criteria">
//                                         {rating.criteriaName}
//                                       </div>
//                                       <RatingDisplay rating={rating.rating} />
//                                     </div>
//                                   ))}
//                                   {task.comments?.length > 0 && (
//                                     <div className="comments-section">
//                                       <div className="comments-title">
//                                         Comments:
//                                       </div>
//                                       {task.comments.map((comment, index) => (
//                                         <div
//                                           key={index}
//                                           className="comment-item"
//                                         >
//                                           <div className="comment-text">
//                                             {comment.selectedOption}
//                                           </div>
//                                           {comment.additionalComment && (
//                                             <>
//                                               <div className="additional-comments-title">
//                                                 Additional Comments:
//                                               </div>
//                                               <div className="comment-additional">
//                                                 {comment.additionalComment}
//                                               </div>
//                                             </>
//                                           )}
//                                         </div>
//                                       ))}
//                                     </div>
//                                   )}
//                                 </div>
//                               ) : task.status === 0 ? (
//                                 <div className="pending-text">N/A</div>
//                               ) : (
//                                 <div className="pending-text">
//                                   Pending Review
//                                 </div>
//                               )}
//                             </td>
//                           </tr>
//                         ))}
//                       </tbody>
//                     </table>
//                   </div>
//                 )}
//                 <TaskDetailsModal
//                   isOpen={isModalOpen}
//                   onClose={() => setIsModalOpen(false)}
//                   taskDetails={selectedTaskDetails}
//                   loading={loadingTaskDetails}
//                 />
//               </div>
//             </>
//           )
//         )}
//       </div>
//     </div>
//   );
// };

// export default RatingsDashboard;
