import React, { Component } from "react";
import AudioRecorderJs from "../AudioRecorder";
import styled from "styled-components";
import NoDataFound from "../../elements/NoDataFound";
import { initializeNotifications } from "../../services/notificationService";
import { NotificationPrompt } from "../../components/NotificationComponents/NotificationPopup";

const WrapperQuestion = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  h4 {
    text-align: center;
    padding: 24px 10px 20px 10px;
    font-size: 30px;
    color: #490c6b;
    margin: 8px;
  }
  p {
    font-size: 18px;
    font-weight: 500;
    color: #c90606;
    padding: 0 0 4px 4px;
    //background: #ffe8e8;
    span {
      color: red;
    }
  }
  h2 {
    text-align: center;
    font-size: 16px;
    // background: #459ef83d;
    border-radius: 5px;
    padding: 4px;
    color: #062549;
    margin: 10px;
  }
  h3 {
    text-align: center;
    font-size: 16px;
    // background: #459ef83d;
    border-radius: 5px;
    padding: 4px;
    color: #062549;
    margin: 10px;
  }
  button {
    margin: 0 8px 0 0;
    padding: 8px 14px;
    font-size: 16px;
    border: none;
    background: #521873;
    color: white;
    border-radius: 5px;
    // &:hover {
    //   border: 1px solid #522173;
    //   background: #eadaf3;
    //   color: #521873;
    // }
  }
  @media (max-width: 576px) {
    h4 {
      font-size: 24px;
      padding: 4px 12px;
    }
    h3 {
      font-size: 16px;
    }
    h2 {
      font-size: 16px;
    }
    p {
      font-size: 16px;
    }
  }
`;
const WrapperNextBtn = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  border-top: 2px solid;
  //   display: flex;
  //   justify-content: space-between;
  //   align-items: center;
  //   margin: 0 0 8px 0;

  button {
    margin: 0 10px;
    padding: 10px 16px;
    font-size: 16px;
    border: none;
    background: #1b4da3;
    color: white;
    border-radius: 5px;
    cursor: pointer;
    transition: background 0.2s, color 0.2s;

    &:active {
      background: #eadaf3; /* Lighter background when button is pressed */
      color: #1b4da3; /* Change text color when button is pressed */
    }

    &:disabled {
      opacity: 0.6;
      cursor: not-allowed;
      background: #1b4da3;
      color: #868686;
    }
  }

  p {
    background: #1b4da3;
    color: white;
    padding: 8px;
    border-radius: 4px;
    font-size: 16px;
    margin: 0 10px;
  }
`;

export const AudioTaskContainer = styled.div`
  //   min-width: 450px;
  //   background: white;
  //   margin: 0;
  //   padding-bottom: 4px;

  h1 {
    text-align: center;
    margin: 4px;
    font-size: 32px;
    border: 1px dashed #0000007d;
    border-radius: 2px;
  }
  h2 {
    text-align: center;
    margin: 4px;
    font-size: 32px;
    color: black;
  }
  @media (max-width: 576px) {
    h1 {
      text-align: center;
      margin: 4px;
      font-size: 18px;
      max-height: 140px;
      overflow: scroll;
      min-height: 36px;
      background: #0b0b0b0f;
      color: #000000d1;
      padding: 4px;
    }
    h2 {
      text-align: center;
      margin: 1px;
      font-size: 20px;
    }
  }
  @media (max-width: 375px) {
    h1 {
      text-align: center;
      margin: 4px;
      font-size: 18px;
      max-height: 120px;
      overflow: scroll;
      min-height: 36px;
      background: #0b0b0b0f;
      color: #000000d1;
      padding: 4px;
    }
    h2 {
      text-align: center;
      margin: 1px;
      font-size: 20px;
    }
  }
`;
const DescriptionSection = styled.div`
  background: #50af52;
  text-align: center;
  h3 {
    text-align: center;
    font-size: 16px;
    border-radius: 5px;
    padding: 4px;
    color: white;
    margin: 10px;
  }
  h2 {
    font-size: 24px;
    border-radius: 0;
    background: transparent;
    margin: 0;
    padding: 6px;
    color: white;
    font-weight: 600;
  }
  @media (max-width: 576px) {
    h3 {
      font-size: 17px;
      margin: 0 4px 2px 4px;
      font-weight: 500;
    }
    h2 {
      font-size: 18px;
      border-radius: 0;
      background: transparent;
      padding: 6px 6px 2px 6px;
    }
  }
`;
export default class QuestionListUI extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentIndex: 0,
      deleteAudio: false,
      audioUrl: "",
      audioState: {},
      media: { url: "", mediaType: "" },
      isChangeClick: false,
      showNotificationPrompt: false,
    };
  }
  // notification permission popup handlers:
  handleSubmitSuccess = () => {
    try {
      if (Notification.permission !== "granted") {
        const lastPrompt = localStorage.getItem("fcm_notification_prompt");
        const lastPromptData = lastPrompt ? JSON.parse(lastPrompt) : null;

        // Check if it's been at least a day since last prompt
        const oneDayInMs = 24 * 60 * 60 * 1000;
        const timeSinceLastPrompt = lastPromptData
          ? Date.now() - lastPromptData.timestamp
          : null;

        if (!lastPromptData || timeSinceLastPrompt >= oneDayInMs) {
          this.setState({ showNotificationPrompt: true });
          localStorage.setItem(
            "fcm_notification_prompt",
            JSON.stringify({
              timestamp: Date.now(),
              pathname: window.location.pathname,
            })
          );
        }
      }
    } catch (error) {
      console.error("Error checking notification permission:", error);
    }
  };

  handleRequestPermission = async () => {
    try {
      const isInitialized = await initializeNotifications();
      if (isInitialized) {
        this.setState({ showNotificationPrompt: false });
        localStorage.setItem(
          "fcm_notification_prompt",
          JSON.stringify({
            timestamp: Date.now(),
            pathname: window.location.pathname,
          })
        );
      }
    } catch (error) {
      console.error("Error requesting notification permission:", error);
    }
  };

  handleCloseNotification = () => {
    this.setState({ showNotificationPrompt: false });
    localStorage.setItem(
      "fcm_notification_prompt",
      JSON.stringify({
        timestamp: Date.now(),
        pathname: window.location.pathname,
      })
    );
  };

  handleAudioStateChange = (state) => {
    const { media } = this.state;
    media.url = state.fileUrl;
    media.mediaType = "audio/mpeg";
    this.setState({ media });
    this.setState({ audioUrl: state.fileUrl, audioState: state });
  };

  handlePrev = () => {
    this.setState((prevState) => ({
      currentIndex: Math.max(prevState.currentIndex - 1, 0),
      media: { url: "", mediaType: "" },
      isChangeClick: !prevState.isChangeClick,
    }));
  };

  handleNext = () => {
    this.setState((prevState) => ({
      currentIndex: Math.min(
        prevState.currentIndex + 1,
        this.props.surveyQuestionDataList.length - 1
      ),
      media: { url: "", mediaType: "" },
      isChangeClick: !prevState.isChangeClick,
    }));
  };

  render() {
    const { surveyQuestionDataList } = this.props;
    const { currentIndex, showNotificationPrompt } = this.state;
    if (
      !Array.isArray(surveyQuestionDataList) ||
      surveyQuestionDataList.length === 0
    ) {
      return (
        <div
          style={{
            textAlign: "center",
            position: "absolute",
            top: "50%",
            left: "50%",
            width: "100%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <NoDataFound message={"No question is available, try again later"} />
        </div>
      );
    }
    // Safely get the current question
    const question = surveyQuestionDataList[currentIndex];
    return (
      <WrapperQuestion>
        <div style={{ background: "black" }}>
          <DescriptionSection>
            <h2>Task Description</h2>
            <h3>{question.TypeDescTrans || question.Description}</h3>
          </DescriptionSection>
          <DescriptionSection style={{ background: "#7ab240" }}>
            <h2>Task Instructions</h2>
            <h3>{question.NoteDescTrans || question.Note}</h3>
          </DescriptionSection>
          <DescriptionSection style={{ background: "#f7bf45" }}>
            <h2>Task value</h2>
            <h3>{question.Sicca ? question.Sicca : 0} Tasker Sicca </h3>
          </DescriptionSection>
        </div>
        <div style={{ width: "100%" }}>
          <AudioTaskContainer>
            <h2>The Task (Record This)</h2>
            <h1>{question.VoiceDescription}</h1>
          </AudioTaskContainer>
          <WrapperNextBtn>
            <div style={{ margin: "8px 20px 8px 5px" }}>
              <AudioRecorderJs
                name="audioSurvey"
                deleteAudio={this.state.deleteAudio}
                onChange={this.handleAudioStateChange}
                userId={question.userId}
                questionData={question}
                isChangeClick={this.state.isChangeClick}
                updateSurveyQuestionData={this.props.updateSurveyQuestionData}
                latitude={this.props.latitude}
                longitude={this.props.longitude}
                requestLocation={this.props.requestLocation}
                onSubmitSuccess={this.handleSubmitSuccess}
              />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "4px",
              }}
            >
              <button onClick={this.handlePrev} disabled={currentIndex === 0}>
                Previous
              </button>
              <p>
                {currentIndex + 1}/{surveyQuestionDataList.length}
              </p>
              <button
                onClick={this.handleNext}
                disabled={currentIndex === surveyQuestionDataList.length - 1}
              >
                Next
              </button>
            </div>
          </WrapperNextBtn>
        </div>
        {showNotificationPrompt && (
          <NotificationPrompt
            onRequestPermission={this.handleRequestPermission}
            onClose={this.handleCloseNotification}
          />
        )}
      </WrapperQuestion>
    );
  }
}
