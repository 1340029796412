// src/services/notificationService.ts
import { getToken, onMessage } from "firebase/messaging";
import api from "../api";
import { messaging, analytics } from "../config/firebaseConfig";
import { logEvent } from "firebase/analytics";

const vapidKey = process.env.REACT_APP_FIREBASE_VAPID_KEY;

// Generate a simple device identifier based on user agent and screen properties
function generateDeviceId() {
  const userAgent = navigator.userAgent;
  const screenWidth = window.screen.width;
  const screenHeight = window.screen.height;
  const pixelRatio = window.devicePixelRatio;
  const platform = navigator.platform;

  return btoa(
    `${userAgent}-${screenWidth}-${screenHeight}-${pixelRatio}-${platform}`
  );
}

// Get the stored FCM token for this device
export function getStoredFCMToken() {
  const deviceId = generateDeviceId();
  const storedData = localStorage.getItem(`fcm_token_${deviceId}`);
  return storedData ? JSON.parse(storedData) : null;
}

// Store FCM token for this device
function storeFCMToken(token) {
  const deviceId = generateDeviceId();
  localStorage.setItem(
    `fcm_token_${deviceId}`,
    JSON.stringify({
      token,
      timestamp: Date.now(),
    })
  );
}

async function sendTokenToServer(token) {
  try {
    const sessionKey = localStorage.getItem("sessionKey");
    if (!sessionKey) {
      console.error("No session key found");
      return;
    }

    await api.post("/tasker-dashboard/save-fcm-token", {
      token,
      sessionKey,
    });

    // Log successful token registration
    logEvent(analytics, "fcm_token_registered", {
      deviceId: generateDeviceId(),
    });

    storeFCMToken(token);
  } catch (error) {
    console.error("Error saving FCM token:", error);

    // Log failed token registration
    logEvent(analytics, "fcm_token_registration_failed", {
      deviceId: generateDeviceId(),
      error: error.message,
    });
  }
}

function setupForegroundHandler() {
  onMessage(messaging, (payload) => {
    if (Notification.permission === "granted" && payload.notification) {
      const { title, body } = payload.notification;

      // Log notification received event
      logEvent(analytics, "notification_received", {
        title: title,
        messageId: payload.data?.messageId,
      });

      const notification = new Notification(title || "New Notification", {
        body,
        icon: "../../public/logo3.ico",
        data: payload.data,
      });

      // Track when notification is clicked
      notification.onclick = () => {
        logEvent(analytics, "notification_clicked", {
          title: title,
          messageId: payload.data?.messageId,
        });
      };
    }
  });
}

async function setupFCM() {
  try {
    // Check if we already have a valid token for this device
    const storedToken = getStoredFCMToken();

    // Get a new token

    const currentToken = await getToken(messaging, {
      vapidKey,
      serviceWorkerRegistration:
        await navigator.serviceWorker.getRegistration(),
    });

    if (currentToken) {
      // Only send token to server if it's different from stored token
      if (!storedToken || storedToken.token !== currentToken) {
        await sendTokenToServer(currentToken);
      }
      setupForegroundHandler();
    } else {
      console.error("Failed to get FCM token");
    }
  } catch (error) {
    console.error("Error setting up FCM:", error);
  }
}

export async function initializeNotifications() {
  try {
    if ("Notification" in window) {
      if (Notification.permission !== "granted") {
        const permission = await Notification.requestPermission();

        // Log permission request result
        logEvent(analytics, "notification_permission_response", {
          permission: permission,
        });

        if (permission === "granted") {
          await setupFCM();
          return true;
        }
      } else {
        await setupFCM();
        return true;
      }
    }
    return false;
  } catch (error) {
    console.error("Error initializing notifications:", error);

    // Log initialization errors
    logEvent(analytics, "notification_initialization_error", {
      error: error.message,
    });

    return false;
  }
}

export async function checkNotificationPermission() {
  return Notification.permission === "granted";
}

export const notificationService = {
  initialize: initializeNotifications,
  checkPermission: checkNotificationPermission,
  getStoredToken: getStoredFCMToken,
};
